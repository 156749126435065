
<template>
  <div class="article-content">
    <v-container class="px-0 pt-0" fluid>
      <v-img 
        :src="requireHeroImg"
        height="350"
      />
    </v-container>
    <v-container class="px-15">
      <v-row>
        <v-col cols="10">
          <h1>{{title}}</h1>
          <p class="text-caption mb-0">By <strong>{{author}}</strong></p>
          <p class="text-caption mb-0">{{createdDateReadable}}</p>
        </v-col>
        <v-col align="right" cols="2">
          <Clipboard :link="link"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
          <v-img 
            :src="requireHeroImg"
            :aspect-ratio="16/9"
            width="100%"
          />
        </v-col>
        <v-col cols="12">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>          
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import Clipboard from '@/components/Clipboard';

export default {
  name: 'Asking-for-clarification',
  components: {
    Clipboard,
  },
  computed: {
    requireHeroImg(){
      return require(`@/assets/${this.heroImg}`);
    }
  },
  data: () => ({
    title: 'Asking for clarification',
    description: 'description for Asking-for-clarification',
    assetId: '37REidd6FMbAR4BicUp7d5',
    link: '/article/Asking-for-clarification',
    author: 'gs',
    displayType: 'featured',
    heroImg: 'stock/stock-image-office.jpeg',
    created: '1607048480463',
    createdDateReadable: 'Thu Dec 03 2020'
  })
}
</script>
